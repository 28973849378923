<template>
  <b-modal
    id="common-attendance-manage-modal"
    footer-bg-variant="light"
    :title="title"
    size="lg"
    lazy
  >
    <div class="d-flex flex-column flex-lg-row border-bottom pb-5">
      <CommonEmployeeSummary :item="employee"></CommonEmployeeSummary>
      <EmployeeAttendanceManageDate
        type="full"
        :date="getPureDate"
      ></EmployeeAttendanceManageDate>
    </div>

    <CommonAttendanceManagementForm
      @onIdChanged="handleIdChange"
      @form-validated="updateAttStatus"
      :changed-id="changedId"
      :employee="employee"
      :date="date"
      :engage-form="engageForm"
    ></CommonAttendanceManagementForm>

    <template #modal-footer="{ ok, cancel }">
      <b-button
        variant="light-primary"
        class="btn-hover-danger"
        @click="cancel()"
        >Close
      </b-button>
      <b-button
        variant="primary"
        v-on:click="engageForm = !engageForm"
        v-bind:class="{
          'spinner spinner-light spinner-right': isBusy,
        }"
        >Submit</b-button
      >
    </template>
  </b-modal>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import EmployeeAttendanceManageDate from "@/modules/company/components/shared/EmployeeAttendanceManageDate";
import CommonAttendanceManagementForm from "@/modules/core/components/attendance/CommonAttendanceManagementForm";
import dayjs from "dayjs";

export default {
  name: "CommonAttendanceManageModal",
  components: {
    CommonAttendanceManagementForm,
    EmployeeAttendanceManageDate,
    CommonEmployeeSummary,
  },

  props: {
    employee: {
      type: Object,
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
    hold: {
      type: Boolean,
      default: false,
    },
    changedId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      isBusy: this.hold,
      statusId: this.changedId,
      engageForm: false,
    };
  },

  methods: {
    /**
     * Update attendance status.
     */
    updateAttStatus() {
      const form = {
        id: this.employee.id,
        att_status_id: this.statusId,
        date: this.attendance.date,
        remark: "Set by admin",
      };

      this.$emit("onSubmitClick", form);
    },

    /**
     * Handle attendance statud id change
     *
     * @param id
     */
    handleIdChange(id) {
      console.log("Id change: ", id);
      this.statusId = id;
      console.log("Status Id now is", this.statusId);
    },
  },

  computed: {
    title() {
      return `Manage ${this.employee.name} Attendance`;
    },
    date() {
      return new dayjs(this.attendance.date).format();
    },
    getPureDate() {
      return new dayjs(this.attendance.date).toDate();
    },
  },
};
</script>

<style scoped></style>
