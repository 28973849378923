<template>
  <div>
    <h5 class="font-weight-bolder my-10">Modify Attendance</h5>
    <b-form class="mt-5">
      <b-form-group label="Status:" label-cols-lg="2">
        <b-form-select
          v-model="form.statusId"
          :options="statusList"
          :state="validateState('statusId')"
        ></b-form-select>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "dayjs";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";

export default {
  name: "CommonAttendanceManagementForm",
  mixins: [validationMixin],
  props: {
    changedId: {
      type: Number,
      default: null,
    },
    date: {
      required: true,
      type: String,
      default: dayjs().format(),
    },
    employee: {
      required: true,
      type: Object,
    },
    engageForm: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  validations: {
    form: {
      statusId: {
        required,
      },
    },
  },
  data: function () {
    return {
      form: {
        statusId: null,
      },
      statusList: [
        { value: "1", text: "Present" },
        { value: "2", text: "Absent" },
      ],
    };
  },
  mounted() {},
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
  computed: {
    ...mapGetters({}),
  },
  watch: {
    "form.statusId": function (newId) {
      console.log("New Id", newId);
      this.$emit("onIdChanged", newId);
    },
    changedId(newId) {
      console.log("Changed New ID", newId);
    },
    engageForm(newEngage) {
      console.log(newEngage);

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit("form-validated", this.form);
    },
  },
};
</script>

<style scoped></style>
