<template>
  <div>
    <h5 class="font-weight-bolder my-10">Update Attendance Time</h5>
    <b-form class="form" v-on:submit.prevent="save">
      <div class="d-flex">
        <!-- begin: Time-In    -->
        <b-form-group
          class="ml-2 flex-fill"
          :label="$t('GROUP.SCHEDULE.START')"
        >
          <b-form-timepicker
            v-model="form.time_in"
            :state="validateState('time_in')"
          ></b-form-timepicker>
        </b-form-group>

        <b-form-group class="ml-2 flex-fill" :label="$t('GROUP.SCHEDULE.END')">
          <b-form-timepicker
            class=""
            v-model="form.time_out"
            :state="validateState('time_out')"
          ></b-form-timepicker>
        </b-form-group>
        <!-- end: Time-In    -->
      </div>

      <div class="d-flex justify-content-end pt-3">
        <b-button
          variant="primary"
          type="submit"
          ref="submit"
          v-bind:class="{ 'spinner spinner-right spinner-light': isBusy }"
        >
          {{ $t("USERS.DETAILS.SUBMIT") }}
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CommonAttendanceUpdateTimeForm",
  mixins: [validationMixin],
  props: {
    attendance: {
      type: Object,
      required: true,
    },
    isBusy: {
      type: Boolean,
      required: true,
      default: true,
    },
  },
  data() {
    return {
      form: {
        time_in: this.attendance.in_fmt,
        time_out: this.attendance.out_fmt,
      },
    };
  },
  validations: {
    form: {
      time_in: {
        required,
      },
      time_out: {
        required,
      },
    },
  },

  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },

    save() {
      console.log("Emit Modal to submit event");
      this.$v.form.$touch();

      if (this.$v.form.$anyError) {
        console.log("Form in complete");
        return;
      }

      this.$emit("submit", {
        id: this.attendance.id,
        time_in: this.form.time_in,
        time_out: this.form.time_out,
      });
    },
  },
};
</script>

<style scoped></style>
