<template>
  <b-modal
    id="common-attendance-update-time-modal"
    footer-bg-variant="light"
    :title="title"
    size="lg"
    lazy
    hide-footer
  >
    <div class="d-flex flex-column flex-lg-row border-bottom pb-5">
      <CommonEmployeeSummary :item="employee"></CommonEmployeeSummary>
      <EmployeeAttendanceManageDate
        type="full"
        :date="date"
      ></EmployeeAttendanceManageDate>
    </div>

    <CommonAttendanceUpdateTimeForm
      @submit="updateAttendanceTime"
      :attendance="attendance"
      :is-busy="isBusy"
    ></CommonAttendanceUpdateTimeForm>
  </b-modal>
</template>

<script>
import CommonEmployeeSummary from "@/modules/core/components/company/CommonEmployeeSummary";
import EmployeeAttendanceManageDate from "@/modules/company/components/shared/EmployeeAttendanceManageDate";
import CommonAttendanceUpdateTimeForm from "@/modules/core/components/attendance/CommonAttendanceUpdateTimeForm";
export default {
  name: "CommonAttendanceUpdateTimeModal",
  components: {
    CommonAttendanceUpdateTimeForm,
    EmployeeAttendanceManageDate,
    CommonEmployeeSummary,
  },
  props: {
    employee: {
      type: Object,
      required: true,
    },
    attendance: {
      type: Object,
      required: true,
    },
    hold: {
      type: Boolean,
      default: false,
    },
  },
  data: function () {
    return {
      isBusy: this.hold,
    };
  },
  methods: {
    updateAttendanceTime(form) {
      this.$emit("onSubmitClick", form);
    },
  },
  computed: {
    title() {
      return `Manage ${this.employee.name} Attendance`;
    },
    date() {
      return new Date(this.attendance.date);
    },
  },
};
</script>

<style scoped></style>
